<ng-template [ngIf]="product.hasLogs" [ngIfElse]="elseBlock">
    <div [ngBusy]="loader"></div>
    <ft-monaco-editor name="content" [(ngModel)]="content" fxFlex disabled language="products-log"></ft-monaco-editor>
    <mat-tab-group animationDuration="0ms" headerPosition="below" mat-align-tabs="center"
                   (selectedTabChange)="loadContent($event.index)" *ngIf="product?.hasLogs">
        <mat-tab *ngFor="let log of product?.logs" [label]="getFileName(log)"></mat-tab>
    </mat-tab-group>

    <button mat-mini-fab color="primary" (click)="downloadFile()" class="download-btn">
        <mat-icon fontSet="mdi" fontIcon="mdi-download"></mat-icon>
    </button>

    <button mat-mini-fab color="warn" (click)="loadContent(currentPath)" class="reload-btn">
        <mat-icon fontSet="mdi" fontIcon="mdi-refresh"></mat-icon>
    </button>
</ng-template>


<ng-template #elseBlock>
    <div class="ft-empty-content" style="margin: 0">
        <mat-icon fontSet="mdi" fontIcon="mdi-alert-circle-outline"></mat-icon>
        <span translate="products.no_log_has_been_found"></span>
    </div>
</ng-template>
