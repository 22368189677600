<form #licenceForm="ngForm" novalidate fxLayout="column" (submit)="updateOnly ? updateLicenceKey() : startInstallation()"
      *ngIf="package.require_licence && !submitted" class="ft-busy-container">
    <div [ngBusy]="loader"></div>
    <div mat-dialog-content fxLayout="column">
        <mat-form-field appearance="outline" class="with-error-label">
            <mat-label>{{'products.product.licence_key' | translate}}</mat-label>
            <textarea matInput [placeholder]="'products.product.licence_key' | translate" rows="5"
                      [(ngModel)]="licenceKey" name="licenceKey" required #licenceKeyModel="ngModel"
                      [ftCustomValidation]="validateField()" [noWatch]="true" [watchedValue]="licenceKey">
            </textarea>
            <mat-error *ngIf="licenceKeyModel.errors?.inValidLicenceKey">{{'products.product.licence_key_invalid' | translate}}</mat-error>
            <mat-error *ngIf="licenceKeyModel.errors?.inValidProduct">{{'products.product.licence_product_invalid' | translate}}</mat-error>
            <mat-error *ngIf="licenceKeyModel.errors?.inValidMachineUuid">{{'products.product.licence_machine_uuid_invalid' | translate}}</mat-error>
            <mat-error *ngIf="licenceKeyModel.errors?.inValidPackageVersion">{{'products.product.licence_package_version_invalid' | translate}}</mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-stroked-button color="primary" [disabled]="licenceForm.invalid" type="submit" class="has-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
            <span translate="common.validate"></span>
        </button>
        <button mat-stroked-button color="warn" type="button"
                [mat-dialog-close]="null" tabindex="-1">
            <span translate="common.cancel"></span>
        </button>
    </div>
</form>

<div fxLayout="column" *ngIf="!package.require_licence || submitted">
    <div mat-dialog-content fxLayout="column">
        <h4 class="progress-title" [ngClass]="{'has-error': message.hasError}">{{message.label}}</h4>
        <mat-progress-bar *ngIf="!message.hasError" [@heightAnimation]
                          [mode]="progressMode" [value]="message.progress">
        </mat-progress-bar>
        <span class="progress-step" *ngIf="!message.hasError" [@heightAnimation]>
            {{message.step}}/{{message.total}}
        </span>

        <p *ngIf="message.hasError" [@heightAnimation] class="stack-error">{{message.error}}</p>
    </div>
    <div mat-dialog-actions *ngIf="message.hasError" [@heightAnimation]>
        <button mat-stroked-button color="warn" type="button"
                [mat-dialog-close]="null" tabindex="-1">
            <span translate="common.cancel"></span>
        </button>
    </div>
</div>
