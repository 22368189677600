import {concat, dropRight, get, indexOf, join, orderBy, reduce} from 'lodash';

export class FileObject {
    path: string;
    // tslint:disable-next-line:variable-name
    handled_path: string;
    dir: string;
    name: string;
    // tslint:disable-next-line:variable-name
    is_folder: boolean;
    size: number;
    separator: string;
    icon: string;
    // tslint:disable-next-line:variable-name
    created_at: string;
    // tslint:disable-next-line:variable-name
    last_changed: string;
    children: any[];
    permissions: any;
    // tslint:disable-next-line:variable-name
    split_path: any[];
    // tslint:disable-next-line:variable-name
    is_parent: any[];

    extension: string;
    type: string;

    constructor(data?) {
        if (data) {
            this.path = data.path;
            this.handled_path = data.handled_path;
            this.dir = data.dir;
            this.name = data.name;
            this.is_folder = data.is_folder;
            this.size = get(data, 'size', null);
            this.separator = data.separator;
            this.icon = data.icon;
            this.created_at = data.created_at;
            this.last_changed = data.last_changed;
            this.children = orderBy(data.children, ['is_folder', 'name'], ['desc', 'asc']);
            this.permissions = data.permissions;
            this.split_path = data.split_path;
            this.is_parent = get(data, 'is_parent', null);
            this.extension = get(data, 'extension', null);
            this.type = get(data, 'type', null);

            if (data.name) {
                const parent = reduce(data, (result, value, key) => {
                    if ((key as any) !== 'children') {
                        result[key] = data[key];
                    }
                    result.is_parent = true;
                    result.path = data.dir;
                    result.handled_path = btoa(data.dir);
                    result.icon = '/assets/parent-folder.svg';

                    return result;
                }, {} as any);
                this.children = concat(parent, this.children);
            }
        }
    }

    prepareQueryParams(name): any {
        const names = this.split_path;
        const i = indexOf(names, name);

        if (i !== names.length - 1) {
            const folders = dropRight(names, names.length - i - 1);
            const path = join(folders, this.separator);
            return {path: btoa(folders.length > 1 ? path : '/')};
        }
        return;
    }

    getPermission() {
        const r = this.permissions.r ? 'r' : '-';
        const w = this.permissions.w ? 'w' : '-';
        const x = this.permissions.x ? 'x' : '-';
        return `${r}${w}${x}`;
    }
}
