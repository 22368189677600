import {Component, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import {FileManagerService} from '../../services/file-manager.service';
import {GeneralPurposeService} from '@ft/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FileObject} from '../../classes/folder';


@Component({
    selector: 'ftm-file-item',
    templateUrl: './file-item.component.html',
    styleUrls: ['./file-item.component.scss']
})
export class FileItemComponent implements OnInit {
    @Input() file: FileObject = new FileObject();

    @HostBinding('class') private _class = 'ft-file-item';

    private readonly moveLabel: string;
    private readonly movePlaceholderLabel: string;
    private readonly path: string;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private _fmService: FileManagerService,
        private _ftService: GeneralPurposeService,
        private _translate: TranslateService
    ) {
        this.moveLabel = this._translate.instant('file_manager.move');
        this.movePlaceholderLabel = this._translate.instant('file_manager.move_file_placeholder');
        this.path = this._translate.instant('file_manager.path_copied');
    }

    get disabledRead() {
        return !this.file.permissions.r;
    }

    get disabled() {
        return !this.file.permissions.w || this.file.dir === '/home';
    }

    ngOnInit() {
    }

    @HostListener('click')
    openFolder() {
        if (this.file.permissions.r) {
            const queryParams = {path: this.file.handled_path, folder: this.file.is_folder};
            this._router.navigate(this._fmService.rootUrl, {queryParams}).then();
        }
    }

    delete() {
        this._fmService.delete(this.file)
            .subscribe(
                suc => this._fmService.changeUrl(),
                err => this.showSnackBar(err)
            );
    }

    copyPath(ev) {
        ev.stopPropagation();
        navigator.clipboard.writeText(this.file.path).then();
        this.showSnackBar(this.path);
    }

    download() {
        this._fmService.download(this.file)
            .subscribe(
                download => download(),
                err => this.showSnackBar(this._translate.instant(`file_manager.${err.description}`))
            );
    }

    renameModal() {
        this._fmService.rename(this.file)
            .subscribe(
                suc => this._fmService.changeUrl(),
                err => {
                    if (err !== false) this.showSnackBar(err);
                }
            );
    }

    moveModal() {
        this._fmService.moveOrReplace(this.file).subscribe(
            res => this._fmService.changeUrl(),
            err => this.showSnackBar(err),
        );
    }

    showSnackBar(msg) {
        this._snackBar.open(msg.error ? this._translate.instant(`file_manager.${msg.error.description}`) : msg);
    }

}
