import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {chain} from 'lodash';
import {Subscription} from 'rxjs';
import {HOST_PATERN, SEMVER_PATERN} from '@ft/core';
import {ProductModel} from '../../classes/product.model';
import {ProductsService} from '../../services/products.service';

@Component({
    selector: 'ftm-product-update',
    templateUrl: './product-update.dialog.html',
    styleUrls: [
        './product-update.dialog.scss'
    ]
})
export class ProductUpdateDialog {
    public loader: Subscription;
    public product: ProductModel;

    public hostPattern: string = HOST_PATERN;
    public semverPattern: string = SEMVER_PATERN;

    constructor(
        private _service: ProductsService,
        private _dialogRef: MatDialogRef<ProductUpdateDialog>,
        @Inject(MAT_DIALOG_DATA) data: any) {
        this.product = chain(data).get('product').cloneDeep().value() as ProductModel;
    }

    submit() {
        this.loader = this._service.updateProduct(this.product)
            .subscribe(data => this._dialogRef.close(data));
    }

    addBackup() {
        this.product.backups.push({cron: '0 11 * * *', destination: '', backups: 7});
    }

    removeBackup(index, ev) {
        ev.stopPropagation();
        this.product.backups.splice(index, 1);
    }
}
