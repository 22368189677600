<div class="ft-parent-layout ft-monaco-container mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <nav mat-tab-nav-bar backgroundColor="accent" fxLayout="column">
        <a mat-tab-link *ngFor="let link of products; trackBy: trackByFn"
           [routerLink]="link.path"
           routerLinkActive #rla="routerLinkActive"
           [active]="rla.isActive">
            {{link.name}}
        </a>

        <span fxFlex></span>

        <button mat-button class="with-icon" (click)="openManageDialog()" disabled>
            <mat-icon fontSet="mdi" fontIcon="mdi-cog"></mat-icon>
            <span translate="products.setups.handle_setups"></span>
        </button>
    </nav>
    <router-outlet></router-outlet>
</div>
