import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {get} from 'lodash';
import {HEIGHT_ANIMATION} from '@ft/core';
import {ProductsService} from '../../services/products.service';
import {ExecutionMessage} from '../../classes/execution-message.model';
import {Subscription} from 'rxjs';


@Component({
    selector: 'ftm-package-execution',
    templateUrl: './package-execution.dialog.html',
    animations: [
        HEIGHT_ANIMATION
    ],
    styleUrls: [
        './package-execution.dialog.scss'
    ]
})
export class PackageExecutionDialog implements OnInit {
    public readonly package: any;
    public licenceKey = '';
    public submitted = false;
    public updateOnly = false;
    public loader: Subscription = null;
    public message: ExecutionMessage = new ExecutionMessage({});

    constructor(
        private _service: ProductsService,
        private _dialogRef: MatDialogRef<PackageExecutionDialog>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.package = get(data, 'package', {});
        this.updateOnly = get(data, 'updateOnly', false);
    }

    ngOnInit() {
        if (!this.package.require_licence && !this.updateOnly) this.startInstallation();
    }

    public validateField() {
        return this._service.validatePackageLicence(this.package, this.licenceKey);
    }

    public startInstallation() {
        this.submitted = true;
        this._service.startExecution(this.package, this.licenceKey)
            .subscribe(data => {
                if (data.hasInstance) this._dialogRef.close(data.instance);
                else if (this.message.hasError) return;
                else this.message = data;
            });
    }

    public updateLicenceKey() {
        this.loader = this._service.updateLicenceKey(this.package, this.licenceKey)
            .subscribe(() => this._dialogRef.close());
    }

    public get progressMode() {
        if (!this.message) return 'buffer';
        else if (this.message.progress === -1) return 'indeterminate';
        else return 'determinate';
    }
}
