<div fxFlex fxLayout="column" class="upload-drop-container" ngfDrop
     (fileChange)="handlePackage($event)" (validDragChange)="validDrag = $event"
     [ngClass]="{'on-drag': validDrag}">
    <ft-generic-table fxFlex
                      [remote]="false"
                      [source]="source$"
                      [selectable]="false"
                      [hasPagination]="false"
                      [columns]="columns">
    </ft-generic-table>
</div>

<button mat-mini-fab color="primary" ngfSelect (fileChange)="handlePackage($event)">
    <mat-icon fontSet="mdi" fontIcon="mdi-upload"></mat-icon>
</button>
