<div class="ft-parent-layout ft-monaco-container mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <mat-toolbar color="accent" fxLayout="row">
        <div class="ft-module-icon">
            <button mat-icon-button (click)="goToParent()" [disabled]="!currentFolder?.name">
                <mat-icon fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>

        <h2>{{'file_manager.file_manager' | translate}}</h2>

        <ng-container *ngFor="let n of currentFolder?.split_path; trackBy: trackByFn; first as isFirst">
            <mat-icon fontSet="mdi" fontIcon="mdi-chevron-right" *ngIf="!isFirst"></mat-icon>
            <button mat-button (click)="handlePath(n)" class="no-icon" [class.first]="isFirst">
                <span>{{ n ? n : '/' }}</span>
            </button>
        </ng-container>

        <h2 fxFlex class="access" *ngIf="currentFolder?.is_folder">{{currentFolder?.getPermission()}}</h2>

        <div *ngIf="currentFolder?.is_folder">
            <button mat-button ngfSelect multiple="1" [(files)]="files" (filesChange)="uploadFiles($event)"
                    class="drop-zone icon-only" [disabled]="!currentFolder?.permissions.w">
                <mat-icon fontSet="mdi" fontIcon="mdi-upload"></mat-icon>
            </button>
            <button mat-button (click)="addFile()" class="icon-only" [disabled]="!currentFolder?.permissions.w">
                <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            </button>
            <ft-toolbar-search class="ft-has-icon-buttons" (searchKeyChange)="search($event)"></ft-toolbar-search>
        </div>

    </mat-toolbar>

    <div class="ft-content ft-monaco-container" fxFlex fxLayout="column"
         ngfDrop [(files)]="files" (filesChange)="uploadFiles($event)" *ngIf="currentFolder?.is_folder">
        <div [ngBusy]="busy"></div>
        <ftm-folder [folder]="currentFolder" fxLayout="row wrap" fxLayoutGap="6px"></ftm-folder>
    </div>

    <div class="ft-content" fxFlex fxLayout="column" *ngIf="currentFolder && !currentFolder.is_folder">
        <div [ngBusy]="busy"></div>
        <ftm-file [file]="currentFolder"></ftm-file>
    </div>

</div>
