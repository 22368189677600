import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    host: string;
    port: number;
    username: string;
    password: string;
}

@Component({
    selector: 'ftm-connect',
    templateUrl: './connect.dialog.html',
    styleUrls: ['./connect.dialog.scss']
})
export class ConnectDialog {
    public hide = true;

    constructor(
        public dialogRef: MatDialogRef<ConnectDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    submit() {
        this.dialogRef.close(this.data);
    }
}
